<template>
  <div>
    <!-- <v-card  class="pa-3" > -->

    <v-row dense>
      <v-col cols="12" sm="3">
        <v-checkbox
          v-model="allIncludeTax"
          label="جميع البوالص متضمنه الضريبه"
          color="red darken-3"
          :false-value="0"
          :true-value="1"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-checkbox
          v-model="linesPricing"
          label="التسعير حسب المسار"
          color="orange darken-3"
          :false-value="0"
          :true-value="1"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-checkbox
          v-model="autodataentry"
          label="تشغيل الأدخال المباشر"
          color="orange darken-3"
          :false-value="0"
          :true-value="1"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3">
        <v-checkbox
          v-model="selected.isLandPrice"
          label="تسعير ساحة"
          color="green darken-3"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-form v-model="valid" class="pa-3" ref="addShipmentForm">
      <v-row dense class="mb-0">
        <v-col cols="2">
          <DatePicker-layout
            @myEvent="selected.date = $event"
            :disabled="false"
            :date.sync="selected.date"
            label="التاريخ"
          ></DatePicker-layout>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            outlined
            label="الخدمه"
            ref="num0"
            :items="servicesTypeList"
            item-value="id"
            item-text="name"
            auto-select-first
            validate-on-blur
            :disabled="switch1 == true"
            v-model="selected.serviceTypeId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            outlined
            ref="num1"
            label="المسار"
            :items="lines"
            item-text="name"
            return-object
            auto-select-first
            v-model="selected.line"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-if="selected.line"
            :rules="[allRules.required]"
            dense
            v-model="selected.trip"
            outlined
            ref="num2"
            :items="trips"
            item-text="number"
            item-value="id"
            label="رقم الرحله"
            return-object
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" v-if="selected.serviceTypeId == 3">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            outlined
            v-model="selected.cityId"
            :items="cities"
            label="المدينه"
            item-text="name"
            item-value="id"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" v-if="selected.serviceTypeId == 2">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            outlined
            v-model="selected.dueExternalShipperId"
            :items="dueexternalShippers"
            label="المخرج"
            item-text="name"
            item-value="id"
            auto-select-first
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense class="mb-0">
        <v-col cols="2">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            v-model="selected.paymentType"
            outlined
            ref="num3"
            :items="paymentEnum"
            auto-select-first
            item-text="text"
            item-value="value"
            label="نوع السداد"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" v-if="selected.paymentType === 1">
          <v-autocomplete
            :rules="[allRules.required]"
            dense
            hide-details
            v-model="selected.paymentPaidType"
            outlined
            :items="paymentTypeEnum"
            item-text="text"
            item-value="value"
            label="طريقة الدفع"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" v-if="!autodataentry">
          <v-text-field
            :rules="[allRules.required]"
            dense
            ref="num4"
            outlined
            :loading="loadings.manualNo"
            @blur="getBookNum()"
            @keydown.enter="getBookNum()"
            label="الرقم اليدوى"
            type="number"
            v-model="selected.manualNo"
          ></v-text-field>
        </v-col>
        <v-col cols="2" v-if="!autodataentry">
          <v-text-field
            ref="num5"
            outlined
            label="رقم الدفتر"
            disabled
            dense
            type="number"
            v-model="selected.bookNum"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            dense
            ref="num15"
            outlined
            label="المندوب"
            :items="salesMenData"
            :filter="salesmanFilter"
            item-value="id"
            item-text="name"
            auto-select-first
            v-model="selected.salesmanId"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="my-0 ammar" dense>
        <v-col cols="12" sm="6" class="d-flex">
          <span>
            <v-icon class="m-2" v-if="selected.senderInfo && selected.senderInfo.id"
              >fa fa-user</v-icon
            >
          </span>

          <app-auto-complete
            :searchFn="getClients"
            :text="'name'"
            v-model="selected.senderInfo"
            placeholder="المرسل"
            class="w-100"
            ref="ac_sender"
            :rules="[allRules.required]"
          ></app-auto-complete>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          v-if="
            selected.senderInfo &&
            selected.senderInfo.branches &&
            selected.senderInfo.branches.length > 0
          "
        >
          <v-autocomplete
            dense
            outlined
            v-model="selected.senderBranch"
            :items="selected.senderInfo.branches"
            label="اسم الفرع"
            item-text="name"
            return-object
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            :maxlength="10"
            dense
            outlined
            label="رقم جوال المرسل"
            ref="num12"
            v-if="selected.senderInfo"
            :rules="[allRules.required]"
            v-model="selected.senderInfo.phoneNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            outlined
            label="الرقم الضريبي للمرسل"
            ref="num15"
            :maxlength="15"
            v-if="selected.receiverInfo"
            v-model="selected.senderInfo.taxNum"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex">
          <span>
            <v-icon class="m-2" v-if="selected.receiverInfo && selected.receiverInfo.id"
              >fa fa-user</v-icon
            >
          </span>

          <app-auto-complete
            :searchFn="getClients"
            :text="'name'"
            v-model="selected.receiverInfo"
            placeholder="المستلم"
            class="w-100"
            ref="ac_receiver"
            :rules="[allRules.required]"
          ></app-auto-complete>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          v-if="
            selected.receiverInfo &&
            selected.receiverInfo.branches &&
            selected.receiverInfo.branches.length > 0
          "
        >
          <v-autocomplete
            dense
            outlined
            v-model="selected.receiverBranch"
            :items="selected.receiverInfo.branches"
            label="اسم الفرع"
            item-text="name"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            outlined
            label="رقم جوال المستلم"
            ref="num14"
            :maxlength="10"
            :rules="[allRules.required]"
            v-if="selected.receiverInfo"
            v-model="selected.receiverInfo.phoneNumber"
            append-icon="mdi-magnify"
            @click:append="getclientpricesbynumber(selected.receiverInfo.phoneNumber)"
          >
            ></v-text-field
          >
          <v-menu v-model="menu" :position-x="x" :position-y="y">
            <v-row class="my-0 ammar text-center" dense>
              <v-col cols="12">
                <v-data-table
                  :headers="productsHeaders"
                  :items="allProducts"
                  :server-items-length="allProductstotal"
                  fixed-header
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-plus',
                    nextIcon: 'mdi-minus',
                    'items-per-page-text': 'عدد الاصناف  ' + allProductstotal + '',
                  }"
                >
                  <template v-slot:[`item.includeTax`]="{ item }">
                    <span v-if="item.includeTax">
                      <v-chip label color="info">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </v-chip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            outlined
            label="الرقم الضريبي للمستلم"
            ref="num15"
            :maxlength="15"
            v-if="selected.receiverInfo"
            v-model="selected.receiverInfo.taxNum"
          ></v-text-field>
        </v-col>
        <!-- reciver -->

        <v-col cols="12" sm="6" class="my-0 py-0">
          <v-textarea
            outlined
            hide-details
            name="input-7-1"
            label="الملاحظات"
            v-model="selected.notice"
            rows="2"
          >
          </v-textarea>
        </v-col>

        <!-- auto completes -->
      </v-row>
      <hr />
      <v-row>
        <v-col cols="12" class="my-0 py-0">
          <v-switch
            ref="num16"
            v-model="selected.includeTax"
            label="متضمن  الضريبه"
            @change="updatePrice"
          ></v-switch>
        </v-col>
      </v-row>
      <!-- shipment items start -->
      <div v-if="selected.trip && selected.receiverInfo.name">
        <v-row class="my-0 ammar" dense v-for="(item, index) in selected.items" :key="index">
          <v-col cols="12" sm="2">
            <v-autocomplete
              :rules="[allRules.required]"
              dense
              ref="num17"
              @change="merchandiseChanged(item)"
              outlined
              label="الصنف"
              :items="merchandisesList"
              :filter="merchandiseFilter"
              item-value="id"
              item-text="name"
              auto-select-first
              v-model="item.merchandiseId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              :rules="[allRules.required]"
              dense
              ref="num18"
              outlined
              label="الوحده"
              :items="unitsList"
              item-value="id"
              item-text="name"
              auto-select-first
              v-model="item.unitId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              :rules="[allRules.required]"
              dense
              outlined
              label="الاحجام"
              :items="sizeEnum"
              item-value="value"
              item-text="text"
              auto-select-first
              v-model="item.size"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              :rules="[allRules.required]"
              dense
              outlined
              ref="num19"
              label="العدد"
              type="number"
              v-on:keydown.enter="addNewOne"
              v-model="item.quantity"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              :rules="[allRules.required]"
              dense
              outlined
              label="السعر"
              type="number"
              v-model="item.price"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="2">
            <v-text-field
              dense
              outlined
              filled
              readonly
              label="القيمة المضافة"
              type="number"
              v-model="item.addedValueTax"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="2">
            <v-text-field
              dense
              outlined
              filled
              readonly
              label="المجموع"
              type="number"
              v-model="item.total"
            >
            </v-text-field>
          </v-col>
          <span @click="remove(index)"
            ><i
              class="fas fa-minus-circle"
              v-show="index || (!index && selected.items.length > 1)"
            ></i
          ></span>
          <span @click="addindex(index)"
            ><i
              class="fas fa-plus-circle"
              @click="addindex(index)"
              v-show="index == selected.items.length - 1"
            ></i
          ></span>
        </v-row>

        <!-- shipment items end -->
        <hr />
        <!-- poilsa details -->
        <v-row class="my-0 ammar" dense>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.insurance"
              label="القيمة التقدرية للتأمين"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.duePrice"
              label="المسترد"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="2">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.deliveryPrice"
              label="التوصيل"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.otherServicesPrice"
              label="التغليف"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :rules="[allRules.required]"
              dense
              outlined
              type="number"
              v-model="selected.addedValueTax"
              label="القيمة المضافة"
              filled
              disabled
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <hr />

        <v-row class="justify-content-end border-top-2 align-items.center">
          <v-row v-if="selected.paymentPaidType == 5">
            <v-col cols="12" sm="3">
              <v-text-field
                outlined
                label="كاش"
                dense
                type="number"
                hide
                hide-details
                v-model="selected.cash"
                :rules="[allRules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                outlined
                label="شبكة"
                dense
                type="number"
                hide
                hide-details
                v-model="selected.creditCard"
                :rules="[allRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.netPrice"
              label="الصافي"
              disabled
              filled
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              dense
              outlined
              type="number"
              v-model="selected.totalPrice"
              label="إجمالي السعر"
              @keydown.enter="totalPriceManual"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <!-- </v-card>  -->
    <!-- add product to  -->
    <v-dialog v-model="Productdialog" width="900" persistent>
      <v-card class="pa-5">
        <!-- add product to  -->
        <v-form v-model="validProduct">
          <v-row class="my-0 ammar" dense>
            <v-card-title class="headline mb-3">
              <!-- <i class="fa fa-pen ml-2" ></i> تعديل/إضافة  -->
              اضافة صنف لعميل
            </v-card-title>
            <v-card-text v-can="'Clients.Update'">
              <v-container>
                <v-row class="my-0 ammar" dense>
                  <v-col cols="12" sm="2">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      dense
                      outlined
                      label="الصنف"
                      disabled
                      :items="merchandisesList"
                      item-text="name"
                      return-object
                      auto-select-first
                      v-model="product.merchandiseId"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <v-autocomplete
                      :rules="[allRules.required]"
                      dense
                      outlined
                      disabled
                      label=" الرحله"
                      :items="lines"
                      item-text="name"
                      return-object
                      auto-select-first
                      v-model="product.lineId"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      disabled
                      label="السعر العام"
                      v-model="product.price"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      label="السعر الخاص"
                      :rules="[allRules.required]"
                      v-model.lazy="product.privatePrice"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <v-switch
                      class="mt-0 pt-0"
                      v-model="product.includeTax"
                      dense
                      hide-details
                      label="متضمن  الضريبه"
                    >
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-show="product.includeTax"
                      disabled
                      dense
                      type="number"
                      outlined
                      label=" بعد الضريبه"
                      v-model="product.priceAfterTax"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-can="'Clients.Update'"
            color="success"
            @click="addNewMerchandis()"
            :disabled="!validProduct"
          >
            <v-icon>mdi-plus</v-icon>اضافه
          </v-btn>

          <v-btn color="red darken-1" text @click="cancelMerchandis()">ألغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import salesMenApi from "../services/SalesmenService.js";
import tripsApi from "../services/TripsService";
import lineApi from "../services/LinesService.js";
import servicesTypesApi from "../../Settings/services/ServiceTypesService";
import ClientsService from "../services/ClientsService.js";

import unitsApi from "../../Settings/services/UnitsService";
import MerchandisesApi from "../services/MerchandisesService";
import ApiService from "../services/ShipmentService";
import PricesApi from "../services/PricesService";
import CityService from "../../Settings/services/CityService";
import BookService from "../services/BooksService";
import Enums from "./../../../plugins/enums";
import ShipmentService from "../services/ShipmentService";
import MerchandisesService from "../services/MerchandisesService";
import ExternalShippersService from "../services/ExternalShippersService";
export default {
  props: ["value", "editMode"],
  data() {
    return {
      loadings: {},
      allProducts: [],
      allProductstotal: 0,
      menu: false,
      x: null,
      y: null,
      valid: false,
      Productdialog: false,
      validProduct: false,
      showsearch: true,
      paymentTypeEnum: Enums.PaymentPaidTypes,
      paymentEnum: Enums.PaymentTypes,
      sizeEnum: Enums.Sizes,
      cities: [],
      dueexternalShippers: [],
      clients: [],
      switch1: false,
      servicesTypeList: [],
      unitsList: [],
      salesMenData: [],
      merchandisesList: [],
      lines: [],
      allIncludeTax: false,
      linesPricing: false,
      autodataentry: false,
      select: null,
      dialog: false,
      product: { includeTax: false },
      message: "",
    };
  },
  created() {
    try {
      const salesMen = salesMenApi.typeHead("", true);
      const line = lineApi.query({ tripState: 1, date: this.selected.date });
      const servicesType = servicesTypesApi.get();
      const units = unitsApi.get();
      CityService.typeHead("", true).then((resp) => (this.cities = resp.data));
      MerchandisesApi.typeHead("", true).then((resp) => (this.merchandisesList = resp.data));
      ExternalShippersService.typeHead("", true).then((resp) => {
        this.dueexternalShippers = resp.data;
      });
      Promise.all([salesMen, line, servicesType, units]).then((res) => {
        this.salesMenData = res[0].data;
        this.lines = res[1].data.items;
        this.servicesTypeList = res[2].data;
        this.unitsList = res[3].data;
      });
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    productsHeaders() {
      let header = [
        {
          text: "الصنف",
          value: "merchandise.name",
          align: "center",
          sortable: true,
        },
        { text: "المسار", value: "line.name", align: "center", sortable: true },
        {
          text: "السعر",
          value: "merchandise.price",
          align: "center",
          sortable: true,
        },
        {
          text: "السعر الخاص",
          value: "price",
          align: "center",
          sortable: true,
        },
        {
          text: "السعر بعد الضريبة",
          value: "priceAfterTax",
          align: "center",
          sortable: true,
        },
        {
          text: "متضمن الضريبه",
          value: "includeTax",
          align: "start",
          sortable: true,
        },
      ];
      return header;
    },
    trips() {
      if (!this.selected.line?.trips) return [];
      else
        return this.selected.line.trips.filter((e) =>
          this.filterByDate(e.date, this.selected.date)
        );
    },
  },

  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown);
  },

  methods: {
    show() {
      this.$nextTick(() => {
        this.menu = true;
      });
    },
    getclientpricesbynumber(number) {
      this.allProducts = [];
      PricesApi.gettoaddphoneNumber(number).then((resp) => {
        this.allProducts = resp.data.items;
        this.allProductstotal = resp.data.total;
      });
      this.show();
    },
    addindex(index) {
      this.selected.items.push({
        merchandiseId: "",
        unitId: 1,
        size: 2,
        quantity: 1,
        price: "",
        addedValueTax: "",
        total: "",
      });
    },
    remove(index) {
      this.selected.items.splice(index, 1);
    },
    setSender(model) {
      this.$refs.ac_sender.setModel(model);
    },
    setReceiver(model) {
      this.$refs.ac_receiver.setModel(model);
    },
    salesmanFilter(item, queryText, itemText) {
      return item.id.toString() == queryText;
    },
    merchandiseChanged(item) {
      this.changePrice(item.merchandiseId);
      var query = {
        clientId: parseInt(this.selected.receiverInfo?.id) || 0,
        lineId: parseInt(this.selected.line?.id) || 0,
      };
      MerchandisesService.getPrice(item.merchandiseId, query).then((resp) => {
        var mecrhandise = resp.data;
        this.$set(item, "price", mecrhandise.price);
        let merch = this.merchandisesList.find((i) => {
          return i.id == item.merchandiseId;
        });
        this.$set(item, "merchandise", merch);
        if (this.allIncludeTax) this.selected.includeTax = true;
        else this.selected.includeTax = mecrhandise.includeTax;
      });
    },
    totalPriceManual(e) {
      this.selected.manualEdit = true;
      var self = this;
      if (this.selected.items.length > 1) {
        this.$dialog.notify.error("الغي الاصناف المتعدده لادخال سعر خاص", {
          position: "top-right",
          timeout: 3000,
        });
        return false;
      }
      var totalPrice = this.round(parseFloat(this.selected.totalPrice));
      var addedValueTaxPercentage = this.$store.getters.tax;
      if (this.selected.includeTax) {
        var total =
          totalPrice - (totalPrice / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
        this.selected.addedValueTax = this.round(totalPrice - total);
        this.selected.items[0].addedValueTax = this.round(totalPrice - total);
        this.selected.netPrice = this.round(total);
        if (this.selected.serviceTypeId == 2 && this.selected.dueExternalShipperId == 19)
          this.selected.duePrice = this.round(
            this.round((this.selected.netPrice * 50) / 100) + this.selected.addedValueTax
          );
        this.selected.items[0].price = totalPrice / this.selected.items[0].quantity;
      } else {
        //  this.selected.addedValueTax=this.round(totalPrice*addedValueTaxPercentage);
        //  this.selected.netPrice=this.round(totalPrice-this.selected.addedValueTax) ;

        this.$set(this.selected, "addedValueTax", this.round(totalPrice * addedValueTaxPercentage));
        this.selected.items[0].addedValueTax = this.round(totalPrice * addedValueTaxPercentage);
        this.selected.totalPrice = totalPrice + this.selected.addedValueTax;
        this.$set(this.selected, "netPrice", totalPrice);
        this.selected.items[0].price = totalPrice / this.selected.items[0].quantity;
      }
      setTimeout(() => {
        self.selected.manualEdit = false;
      }, 100);
    },
    getClients(searchTerm) {
      return ClientsService.typeHead(searchTerm, false, true);
    },
    getBookNum(manualNo) {
      this.$set(this.loadings, "manualNo", true);
      if (!manualNo) manualNo = this.selected.manualNo;
      if (!manualNo) return;
      BookService.getBookNum(manualNo)
        .then((resp) => {
          this.$set(this.selected, "bookNum", resp.data.manualNo);
          this.$set(this.selected, "bookId", resp.data.id);
          if (resp.data?.id) {
            var message = `تم تعيين الدفتر رقم ${resp.data.manualNo} `;
            this.$dialog.notify.success(message, {
              position: "top-right",
              timeout: 3000,
            });
          } else {
            this.$dialog.notify.error("لا يوجد دفتر لهذا الرقم اليدوى", {
              position: "top-right",
              timeout: 3000,
            });
          }
        })
        .catch((err) => {
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally((resp) => {
          this.loadings.manualNo = false;
          this.$set(this.loadings, "manualNo", false);
        });
    },
    keyDown(e) {
      if (e.keyCode == 120) {
        console.log("its f9");
        return false;
      }
    },
    reset() {
      this.$refs.addShipmentForm.resetValidation();
      this.$refs.ac_receiver.reset();
      this.$refs.ac_sender.reset();
    },

    updatePrice() {
      if (this.selected.manualEdit) return;
      var shipment = this.selected;
      var addedValueTaxPercentage = this.$store.getters.tax;
      shipment.items.forEach((item) => {
        var price = parseFloat(item.price);
        var quantity = parseInt(item.quantity | 0);

        var total = quantity * price;
        item.includeTax = shipment.includeTax;
        if (this.selected.includeTax) {
          var total = total - (total / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
          item.total = this.round(total, 2);
          item.addedValueTax = this.round(price * quantity - total, 2);
        } else {
          var addedValueTax = addedValueTaxPercentage * total;
          item.total = this.round(total + addedValueTax, 2);
          item.addedValueTax = this.round(addedValueTax, 2);
        }
      });
      var itemsTotal = +shipment.items.map((i) => +i.price * +i.quantity).reduce((a, b) => a + b);
      var itemsAddedValueTax = +shipment.items.map((i) => +i.addedValueTax).reduce((a, b) => a + b);

      var totalPrice =
        parseFloat(shipment.insurance | 0) +
        parseFloat(shipment.otherServicesPrice | 0) +
        parseFloat(shipment.deliveryPrice | 0);

      if (this.selected.includeTax) {
        var beforeTax = totalPrice;
        var totalPrice =
          totalPrice - (totalPrice / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;

        shipment.addedValueTax = this.round(beforeTax - totalPrice + itemsAddedValueTax);
        shipment.totalPrice = this.round(beforeTax + itemsTotal);
        shipment.netPrice = this.round(shipment.totalPrice - shipment.addedValueTax);
      } else {
        shipment.addedValueTax = this.round(
          totalPrice * addedValueTaxPercentage + itemsAddedValueTax
        );
        shipment.totalPrice = this.round(totalPrice + itemsTotal + shipment.addedValueTax);
        shipment.netPrice = this.round(shipment.totalPrice - shipment.addedValueTax);
      }
    },

    clickInput() {
      this.$refs.num8.click();
    },
    async changePrice(id) {
      //const products = [];
      let client = this.selected.receiverInfo;
      let item = this.merchandisesList.find((i) => {
        return i.id == id;
      });
      if (client && client.id) {
        const products = await PricesApi.gettoadd(client);
        //else products = await PricesApi.gettoaddphoneNumber(client);
        let checkIfExist = products.data.items.find((i) => {
          return i.merchandiseId == id && i.lineId == this.selected.line.id;
        });
        if (!checkIfExist) {
          this.$dialog
            .info({
              text: "هذا الصنف غير موجود للعميل هل تريد اضافته ؟",
              title: "  اضافه صنف   ",
              persistent: true,
              actions: {
                true: {
                  text: "نعم",
                  color: "green",
                  handle: () => {
                    this.Productdialog = true;
                    this.product.price = item.price;
                    this.product.merchandiseId = item;
                    this.product.lineId = this.selected.line;
                  },
                },
                false: {
                  text: "لا",
                },
              },
            })
            .then((res) => {});
        }
      }
    },
    merchandiseFilter(item, queryText, itemText) {
      return item.id.toString().includes(queryText) || item.name.includes(queryText);
    },
    addNewMerchandis() {
      this.product.merchandiseId = this.product.merchandiseId.id;
      this.product.lineId = this.product.lineId.id;
      this.product.clientId = this.selected.receiverInfo.id;
      this.product.price = this.product.privatePrice;
      let data = {
        clientId: this.product.clientId,
        clientData: this.product,
      };
      PricesApi.add(data).then((resp) => {
        this.product = { includeTax: false };
        let newMerchandis = {
          id: resp.data.merchandiseId,
          includeTax: resp.data.includeTax,
          price: resp.data.price,
        };
        this.Productdialog = false;
        let shipment = this.selected.items[0];
        this.$set(shipment, "merchandiseId", newMerchandis.id);
        this.$set(shipment, "price", newMerchandis.price);
        this.$set(this.selected, "includeTax", newMerchandis.includeTax);
        this.message = "تم إضافة صنف بنجاح";
        this.$dialog.notify.success(this.message, {
          position: "top-right",
          timeout: 3000,
        });
      });
    },
    cancelMerchandis() {
      this.Productdialog = false;
    },
    validate() {
      this.$refs.addShipmentForm.validate();
    },
  },

  watch: {
    valid(val) {
      console.log("is valid", val);
      this.$emit("valid", val);
    },
    selected(val) {
      console.log("selected", val);
    },
    "selected.date"(date) {
      const line = lineApi.query({ tripState: 1, date: date });
      Promise.all([line]).then((res) => {
        this.lines = res[0].data.items;
      });
    },
    "selected.trip"(trip) {
      this.selected.tripId = trip?.id;
    },
    "selected.line"(val) {
      this.selected.line = val;
      if (this.selected.line && this.selected.line.trips) {
        var found = this.selected.line.trips.map((e) => e.id).includes(this.selected.tripId);
        if (!found) {
          this.selected.tripId = null;
        }
      } else {
        this.selected.tripId = null;
      }
    },

    "selected.includeTax": {
      handler() {
        this.updatePrice();
      },
      deep: true,
    },
    switch1: function (newVal) {
      if (newVal == true) {
        this.selected.serviceTypeId = 3;
      }
    },
    allIncludeTax(newVal) {
      if (newVal == 1) this.selected.includeTax = true;
      else this.selected.includeTax = false;
      this.updatePrice();
    },
    linesPricing(newVal) {
      if (newVal == 1) this.merchandiseChanged(this.selected.items[0]);
      else this.merchandiseChanged(this.selected.items[0]);
      this.updatePrice();
    },
    autodataentry(newVal) {
      if (newVal == 1) {
        this.selected.bookNum = null;
        this.selected.manualNo = null;
      }
    },
    "selected.receiverBranch"(newValue, oldValue) {
      if (this.selected.receiverBranch && newValue != oldValue) {
        this.selected.receiverInfo.phoneNumber = this.selected.receiverBranch.phoneNumber;
      }
    },
    "selected.senderBranch"(newValue, oldValue) {
      if (this.selected.senderBranch && newValue != oldValue) {
        this.selected.senderInfo.phoneNumber = this.selected.senderBranch.phoneNumber;
      }
    },
    "selected.insurance"() {
      this.updatePrice();
    },
    "selected.deliveryPrice"() {
      this.updatePrice();
    },
    "selected.otherServicesPrice"() {
      this.updatePrice();
    },
    "product.privatePrice"(newValue) {
      this.selected.mainPrice = +this.product.privatePrice;
      if (this.product.includeTax) {
        var addedValueTaxPercentage = this.$store.getters.tax;
        var price = +this.product.privatePrice;
        if (newValue) {
          var total = price - (price / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
          this.product.priceAfterTax = this.round(total, 2);
          this.$set(this.product, "priceAfterTax", this.product.priceAfterTax);
        } else if (this.product.includeTax == false) {
          this.product.priceAfterTax = this.selected.mainPrice;
        }
      }
    },

    "product.includeTax"(newValue) {
      if (this.product.includeTax) {
        this.mainPrice = +this.product.privatePrice;
        var addedValueTaxPercentage = this.$store.getters.tax;
        var price = +this.product.privatePrice;
        if (newValue == true) {
          var total = price - (price / (1 + addedValueTaxPercentage)) * addedValueTaxPercentage;
          this.product.priceAfterTax = this.round(total, 2);
        } else if (newValue == false) {
          this.product.priceAfterTax = this.selected.mainPrice;
        }
      }
    },
    "selected.items": {
      deep: true,
      handler: function (after, before) {
        this.updatePrice();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

label {
  border: 5px solid #fff;
  padding: 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 40px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: rgb(17, 219, 108);
}

:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
</style>
